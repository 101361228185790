import React,{useState} from "react";
import '../pagescss/Contac.css'
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import emailjs from '@emailjs/browser';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


const  Result=()=>{
  
  return(
    <div className="message-box">
      <p>Mesajınız başarıyla gönderildi</p>
    </div>
  )
}


export default  function Contact(props){
  const {t}=useTranslation(['contact'])


  const [result,showResult]=useState(false);
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_0yvczvr', 'template_rlzpx9n', e.target, 'EjdIHSnfHyW1inoJh')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
      showResult(true)
  };
// hide result
setTimeout(()=>{
  showResult(false)
},3000)

    return(
        
        <div className="contact">
             <Helmet>
    <title>{t('contactTitle')}</title>
    <meta itemProp="name" content="İletişim CSK Yazılım"/>
      <meta itemProp="description" content="CSK Yazılım bir tıkla bize ulaşın."/>
    <meta name="description" content="CSK Yazılım bir tıkla bize ulaşın."/>
    <meta property="og:url" content="http://www.cskyazilim.com/contact"/>
<meta property="og:title" content="İletişim"/>
<meta property="og:type" content="website"/>
<meta property="og:site_name" content="CSK Yazılım"/>
<meta property="og:description" content="CSK Yazılım bir tıkla bize ulaşın."/>
   </Helmet>
            <div className="content">
                <h2>{t('contactHead')}</h2>
               
            </div>
        
            <div className="container">
            <motion.div
    initial={{ opacity: 0 }}
           animate={{ opacity: 1 }}
           exit={{ opacity: 0 }}
           transition={{duration: 0.5}}
        >
                <div className="contactInfo">
                    <div className="box">
                    <Link to={{ pathname: "https://goo.gl/maps/QthnDs5x7BjAof6u7" }} target="_blank" className=" text-decoration-none" aria-label="browse address">   <div className="icon"> <i className='fa fa-map-marker' title="adres" aria-hidden="true"></i> </div></Link>
                    <Link to={{ pathname: "https://goo.gl/maps/QthnDs5x7BjAof6u7" }} target="_blank" className=" text-decoration-none" aria-label="browse address">  <div className="text">
                            <h3>{t('contactAdress')}</h3>
                            <p> Protokol Yolu No:45, 10. Yıl Cd. 34010 Biruni Teknopark Kat:8 Zeytinburnu/İstanbul</p>
                        </div></Link>
                    </div>
                    <div className="box">
                    <a href="tel:+90 555 096 00 00" className=" text-decoration-none" aria-label="call us">     <div className="icon"><i className='fa fa-phone' aria-hidden="true" title="phone"></i></div></a> 
                    <a href="tel:+90 555 096 00 00" className=" text-decoration-none" aria-label="call us">   <div className="text">
                            <h3>{t('contactPhone')}</h3>
                            <p>+90 555 096 00 00</p>
                        </div></a> 
                    </div>
                    <div className="box">
                    <a href={"mailto:info@cskyazilim.com"} className=" text-decoration-none" aria-label="send mail"> <div className="icon"><i className='fa fa-envelope-o' title="email"  aria-hidden="true"></i></div></a>
                    <a href={"mailto:info@cskyazilim.com"} className=" text-decoration-none" aria-label="send mail">     <div className="text">
                            <h3>{t('contactEmail')}</h3>
                            <p>info@cskyazilim.com</p>
                          
                        </div></a>
                    </div>
                    
                </div>
                </motion.div>
   
                <div className="contactForm">
                <motion.div
    initial={{ opacity: 0 }}
           animate={{ opacity: 1 }}
           exit={{ opacity: 0 }}
           transition={{duration: 0.5}}
        >
                    <div className="contactFormMane">
                    <form action="" onSubmit={sendEmail}>
                        <h2>{t('sendMessage')}</h2>
                        <div className="inputBox">
                        <input className="input100" type="text" name="fullName" required />
                        <span>{t("contactName")}</span>
                        </div>
                        <div className="inputBox">
                        <input className="input100" type="text" name="email" required />
                        <span>{t("contactEmail")}</span>
                        </div>
                        <div className="inputBox">
                        <input className="input100" type="text" name="phone" required />
                        <span>{t('contactPhone')}</span>
                        </div>
                        <div className="inputBox">
                        <textarea name="message" required />
                        <span>{t("yourMessage")}</span>
                        </div>
                        <div className="inputBox">
                            <input type="submit" name="" value={t('send')}></input>
                        </div>
                        <div className="row">{result?<Result/>:null}</div>
                    </form>
                    </div>
                    </motion.div>
                </div>
                
            </div>
          
        </div>
    )
}