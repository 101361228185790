import React, { useState, useEffect } from "react";

import "./App.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import LoadingPage from "./components/LoadingPage";
import Routers from "./Routers";

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);
  return <>{loading ? <LoadingPage /> : <Routers />}</>;
}

export default App;
