import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "../App.css";
import "./HeroSection.css";
import { useTranslation } from "react-i18next";

function HeroSection() {
const {t}=useTranslation(["herosection"])
  return (
    <motion.div
    initial={{ opacity: 0.5 }}
           animate={{ opacity: 1 }}
           exit={{ opacity: 0 }}
           transition={{duration: 0.7}}
        >
    <div className="hero-container">
    <video src='/videos/video1.mp4' playsInline   autoPlay loop muted   />
    <div className="text-container flex justify-center">
      <h1 >
         {/* CSK  Yazılım Hizmetleri & Bilişim Teknolojileri A.Ş. */}
         {/* {t("motto")} */}
         {t('motto1')}<br/>
         {t('motto2')}
      </h1>

 
      </div>
      <div className="hero-btns">
        <Link to="/about">
          <button  type="" className=" aboutBtn btns btn-large btt">
           {t("about")}
          </button>
        </Link>
      </div>
    </div>
 
     </motion.div>
  );
}

export default HeroSection;
