import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import "../pagescss/References.css";
import { useTranslation } from "react-i18next";
import { ReferencesPage } from "../constants/referencesPage";


const References = () => {
  const { t } = useTranslation(["references"]);
  return (
    <div className="references">
      <Helmet>
        <title>{t("titleReferences")}</title>
        <meta itemProp="name" content="Referanslar CSK Yazılım" />
        <meta
          itemProp="description"
          content="CSK Yazılım ve Bilişim Teknolojileri A.Ş. İş birliklerimiz."
        />
        <meta
          name="description"
          content="CSK Yazılım ve Bilişim Teknolojileri A.Ş. İş birliklerimiz."
        />
        <meta
          property="og:url"
          content="http://www.cskyazilim.com/references"
        />
        <meta property="og:title" content="Hakkımızda" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="CSK Yazılım" />
        <meta
          property="og:description"
          content="CSK Yazılım ve Bilişim Teknolojileri A.Ş. İş birliklerimiz."
        />
      </Helmet>
      <div className="container ">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-x-[160px] gap-y-[50px]">
  {ReferencesPage.map((reference, index) => (
    <div key={index} className="flex justify-center max-w-[290px] ">
      <Link
        to={{ pathname: reference.url }}
        target="_blank"
        className="text-none text-gray-900 "
      >
        <div 
          className="flex flex-col  items-center justify-center p-5 w-full min-w-[290px]  h-64 md:h-80 md:w-72 lg:w-96 bg-white shadow-md rounded-3xl transform transition-transform hover:scale-105 duration-300 overflow-hidden"
          style={{
            backgroundColor: reference.bg_color
          }}
        >
          <img
            src={reference.img_url}
            className="w-auto h-24 object-contain"
            alt={reference.alt}
          />
        </div>
      </Link>
    </div>
  ))}
</div>




        </motion.div>
      </div>
    </div>
  );
};

export default References;
