import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { isMobile } from 'react-device-detect';
import { References } from '../constants/references';
import { useTranslation } from 'react-i18next';

SwiperCore.use([Pagination, Autoplay]);

const ReferencesSlider = () => {
  const {t}=useTranslation('slider')
  return (
    <div className=" grid grid-cols-12 gap-4 grid-flow-row px-3 bg-gradient-to-r from-gray-800 via-neutral-500 to-stone-400 text-white ">
     
        <h1 className="hidden md:visible col-span-3 md:flex justify-center items-center w-full text-sm md:text-xl lg:text-2xl xl:text-4xl font-extrabold md:border-r  border-cyan-300  tracking-tight leading-none">
       {t('references')}
        </h1>
        <div className="col-span-12 md:col-span-9 w-full h-[109px] ">
        {!typeof window !== "undefined" && (
          <Swiper
            breakpoints={{
              320: {  
                slidesPerView: 2,
              },
              640: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
              1440: {
                slidesPerView: 6,
              },
            }}
            loop={true}
            loopFillGroupWithBlank={true}
            modules={[Pagination, Autoplay]}
            className="mySwiper position-relative"
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            slidesPerView="auto"
            pagination={true}
          >
            {References.map((reference, i) => (
              <SwiperSlide key={i} className="">
           <div className="relative  !w-[140px] !h-[44px ]">
                <img
                  src={reference.img_url}
                  alt={reference.alt ? reference.alt : `slider - ${i}`}
                  className="!w-[140px] !h-[90px]"
                  // style={{ filter: "drop-shadow(2px 2px 4px black)" }}
                  // style={{ 
                  //   objectFit: 'contain',
                  //   filter: "drop-shadow(2px 2px 4px black)",
                  //   width:"140px",
                  //   height:"44px"
                  // }}
                />
                </div>
    
              
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        </div>
      </div>
  );
};

export default ReferencesSlider;
