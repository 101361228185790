import React from "react";
import { useTranslation } from "react-i18next";
import "./LoadingPage.css";

function LoadingPage() {
  const {t}=useTranslation(['loadingPage'])
  return (
    <div className="content-load">
      <div className="loader"></div>
      <h1>
        <span> CSK </span> {t('loadingText')}
      </h1>
    </div>
  );
}

export default LoadingPage;
