import React from 'react';
// import '../../App.css';
import '../pagescss/Services.css'
import { motion } from 'framer-motion';
import Card from '../functions/Card';
import {CardData} from'../enums/CardData'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
export default function Services() {
    const {t}=useTranslation('services')
  return (
 
    <div className='wrapper'>
      <Helmet>
    <title>{t('servicesTitle')}</title>
    <meta itemProp="name" content="Hizmetlerimiz CSK Yazılım"/>
      <meta itemProp="description" content="CSK Yazılım olarak size sunduğumuz hitzmelere göz atabilirsiniz."/>

    <meta name="description" content="CSK Yazılım olarak size sunduğumuz hitzmelere göz atabilirsiniz"/>
    <meta property="og:url" content="http://www.cskyazilim.com/services"/>
<meta property="og:title" content="Hizmetlerimiz"/>
<meta property="og:type" content="website"/>
<meta property="og:site_name" content="CSK Yazılım"/>
<meta property="og:description" content="CSK Yazılım olarak size sunduğumuz hitzmelere göz atabilirsiniz "/>
   </Helmet>
     <h1>{t('servicesHeadLine')}</h1>
      <motion.div
    initial={{ opacity: 0 }}
           animate={{ opacity: 1 }}
           exit={{ opacity: 0 }}
           transition={{duration: 0.5}}
        >
         
      <div className='content-box'>
          {CardData?.map((item,i)=>{
              return <Card   key={i} title={item.title} body={item.body} link={item.link} ıconp={item.ıconp}  />
          })}
          
     
    
        </div>
        </motion.div>
      </div>

  
  )
}