import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../pagescss/Services.css";

function Card({ title, body, link, ıconp }) {
  const { t } = useTranslation(["servicesCard"]);
  return (
    <Link to={link} className=" text-decoration-none">
      <div className="cardd">
        <i className={ıconp}></i>
        <h3 className="cardd-tittlee">{t(title)}</h3>
        <p className="cardd-bodyy">{t(body)}</p>
      </div>
    </Link>
  );
}

export default Card;
