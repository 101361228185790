import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./i18n"
import App from "./App";
import '../src/App.css'
import LoadingPage from "./components/LoadingPage";

ReactDOM.render(
  <>
    <Suspense fallback={LoadingPage}>
      <App />
    </Suspense>
  </>,

  document.getElementById("root")
);
