import { v4 as uuidv4 } from 'uuid';

export const FooterConstants = [
{
  id: uuidv4(),
  title: 'Hakkımızda',
  translated: 'aboutFooter',
  subLinks: [
    { id: uuidv4(), title: 'Hakkmızda', url: '/about', translated: 'aboutFooter' },
  ]
},
{
  id: uuidv4(),
  title: 'İletişim',
  translated: 'contactFooter',
  subLinks: [
    { id: uuidv4(), title: 'Bize Ulaşın', url: '/contact', translated: 'contactUsFooter' },
    { id: uuidv4(), title: 'Destek', url: '/support', translated: 'supportFooter' },
  ]
},
{
  id: uuidv4(),
  title: 'Sosyal Medya',
  translated: 'socialFooter',
  subLinks: [
    { id: uuidv4(), title: 'Instagram', url: 'https://www.instagram.com/cskyazilim', translated: 'Instagram' },
    { id: uuidv4(), title: 'Destek', url: 'https://www.linkedin.com/company/cskyazilim/', translated: 'Linkedin' },
  ]
}

];
