import React from "react";
import "../pagescss/About.css";
import { Link } from "react-router-dom";
import{ motion} from "framer-motion"
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function About() {
  const {t}=useTranslation(['about'])
  return (
    



    <div className="about">
   <Helmet>
    <title>{t('titleAbout')}</title>
    <meta itemProp="name" content="Hakkımızda CSK Yazılım"/>
      <meta itemProp="description" content="CSK Yazılım ve Bilişim Teknolojileri A.Ş. hakkında öğrenmek istediğinz her şey burada"/>
    <meta name="description" content="CSK Yazılım ve Bilişim Teknolojileri A.Ş. hakkında öğrenmek istediğinz her şey burada"/>
    <meta property="og:url" content="http://www.cskyazilim.com/about"/>
<meta property="og:title" content="Hakkımızda"/>
<meta property="og:type" content="website"/>
<meta property="og:site_name" content="CSK Yazılım"/>
<meta property="og:description" content="CSK Yazılım ve Bilişim Teknolojileri A.Ş. hakkında öğrenmek istediğinz her şey burada"/>
   </Helmet>
      <div className="main">
    
   
        <div className="about-text">
        <img src="images/about.jpg" alt="" />
          <h1>{t("aboutHeadLine")}</h1>
          <motion.div
 initial={{ opacity: 0 }}
 animate={{ opacity: 1 }}
 exit={{ opacity: 0 }}
 transition={{duration: 0.7}}
    >
          <h5>
           {t("aboutLabelFirst")} <span>{t('aboutLabelLast')} </span>
          </h5>
          <p className="">
        {t("aboutTextPartOne")}
          </p>
          <p>
        {t("aboutTextPartTwo")}
          </p>
          <p>
        {t("aboutTextPartTree")}
          </p>
          <p>
        {t("aboutTextPartFour")}
          </p>
          <Link to="/contact">
            <button className="aboutBtn" type="">{t('aboutBtn')}</button>
          </Link>
          </motion.div>
        </div>
       
      </div>
    </div>
    // </motion.div>
    
  );
}
