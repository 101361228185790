import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FooterConstants } from "./constants/footer-constant";
import { FiMail, FiMapPin, FiPhone } from "react-icons/fi";

function Footer() {
  const {t} = useTranslation(["footer"])
  return (
    // <div className="footer-containerr">
    //   <div className="footer-links">
    //     <div className="footer-link-wrapper">
    //       <div className="footer-link-items">
    //         <h2>{t('aboutFooter')}</h2>
    //         <Link to="/about">{t('aboutFooter')}</Link>
    //       </div>
    //       <div className="footer-link-items">
    //         <h2>{t('contactFooter')}</h2>
    //         <Link to="/contact">{t('contactUsFooter')}</Link>
    //         <Link to="/support">{t('supportFooter')}</Link>
    //       </div>
    //       <div className="footer-link-items">
    //         <h2>{t('socialFooter')} </h2>

    //         <Link
    //           to={{ pathname: "https://www.instagram.com/cskyazilim" }}
    //           target="_blank"
    //         >
    //           Instagram
    //         </Link>
    //         <Link
    //           to={{ pathname: "https://www.linkedin.com/company/cskyazilim" }}
    //           target="_blank"
    //         >
    //           Linkedin
    //         </Link>
    //       </div>
    //     </div>
    //   </div>

    //   <div className="containerr-foot">
    //     <div className="footer-cont">
    //       <div className="box">
    //         <Link
    //           to={{ pathname: "https://goo.gl/maps/QthnDs5x7BjAof6u7" }}
    //           target="_blank"
    //           className="text-decoration-none"
    //         >
    //           {" "}
    //           <div className="icon">
    //             {" "}
    //             <i className="fa fa-map-marker" aria-hidden="true"></i>{" "}
    //           </div>
    //         </Link>
    //         <Link
    //           to={{ pathname: "https://goo.gl/maps/QthnDs5x7BjAof6u7" }}
    //           target="_blank"
    //           className="text-decoration-none"
    //         >
    //           {" "}
    //           <div className="text">
    //             <h3>{t("adresFooter")}</h3>
    //             <p>
    //             Protokol Yolu No:45, 10. Yıl Cd. 34010 Biruni Teknopark Kat:8 Zeytinburnu/İstanbul
    //             </p>
    //           </div>
    //         </Link>
    //       </div>
    //       <div className="box">
    //         <a href="tel:+90 555 096 00 00"  className="text-decoration-none">
    //           {" "}
    //           <div className="icon">
    //             <i className="fa fa-phone" aria-hidden="true"></i>
    //           </div>
    //         </a>
    //         <a href="tel:+90 555 096 00 00"  className="text-decoration-none">
    //           {" "}
    //           <div className="text">
    //             <h3>{t('phoneFooter')}</h3>
    //             <p>+90 555 096 00 00</p>
    //           </div>
    //         </a>
    //       </div>
    //       <div className="box">
    //         <a href={"mailto:info@cskyazilim.com"}  className="text-decoration-none">
    //           {"  "}
    //           <div className="icon">
    //             <i className="fa fa-envelope-o" aria-hidden="true"></i>
    //           </div>
    //         </a>
    //         <a href={"mailto:info@cskyazilim.com"}  className="text-decoration-none">
    //           {" "}
    //           <div className="text">
    //             <h3>{t("emailFooter")}</h3>
    //             <p>info@cskyazilim.com</p>
    //           </div>
    //         </a>
    //       </div>
    //     </div>
    //   </div>
    //   <small className="website-rights">
    //     {/* CSK Yazılım Hizmetleri ve Bilişim Teknolojileri A.Ş © 2022 | Tüm hakları
    //     saklıdır */}
    //     {t("websiteRightsFooter")}
    //   </small>
    // </div>

    <footer className="p-4 flex justify-center shadow md:px-6 md:pt-8 pb-4 bg-gradient-to-b from-stone-900 via-stone-800 to-stone-700">
        <div className="max-w-8xl w-full">
        <div className="flex flex-col md:flex-row  lg:flex-row xl:flex-row items-center   justify-between flex-wrap space-y-3 md:space-y-0 lg:space-y-0 xl:space-y-0">
        {/* Logo ve Metin */}
    
        <div className="flex flex-col mb-2 md:mb-0 w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
          <img
            src="/images/logo2.png"
            className="h-24 w-[150px] object-cover "
            alt="csk"
          />
        </div>
        {/* Menüler */}
        <div className="flex flex-col  lg:justify-end md:flex-row md:flex-wrap lg:flex-wrap xl:flex-wrap items-start space-x-0 md:space-x-0 md:gap-6 md:pl-10 lg:space-x-0 xl:space-x-0 xl:gap-x-[80px] space-y-2 md:space-y-0 lg:space-y-0 xl:space-y-0 w-full md:w-3/4 lg:w-3/4 xl:w-3/4">
  {FooterConstants.map((category) => (
    <div key={category.id} className="space-y-2 w-full md:w-auto lg:w-auto xl:w-auto md:pl-0 pl-3">

      <ul className="text-gray-100 list-none text-[15px] pl-1">
      <h3 className="font-bold text-baseColor text-lg text-[#f9004d]">{t(category?.translated)}</h3>
        {category.subLinks.map((link) => (
          <li key={link.id}>
            <Link
              to={link.url}
              className="no-underline hover:underline text-gray-100 hover:text-gray-400"
              target={category.title === 'Sosyal Medya' ? "_blank" : ""}
              rel={category.title === 'Sosyal Medya' ? "noopener noreferrer" : ""}
            >
              {t(link?.translated)}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  ))}
</div>
      </div>
      <hr className="my-4  sm:mx-auto border-red-500 lg:my-4" />


      <div className="py-6 md:py-2  md:my-4   flex flex-col-reverse md:flex-row   md:items-center justify-between">
        {/* Metin Alanı */}
        <div className="  text-[10px] lg:text-[15px] text-white  mt-7 md:mt-0 whitespace-nowrap   mb-6 md:mb-0 text-center">
          {t("websiteRightsFooter")}<br/>
          {t("websiteRightsFooter2")}
        </div>

        {/* İkon ve Bilgi Alanı */}
        <div className=" flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row">
    {/* Adres Alanı */}
    <div className="flex flex-row items-start space-x-2 max-w-[250px] group">
    <a 
        href="https://goo.gl/maps/QthnDs5x7BjAof6u7" 
        target="_blank" 
        rel="noopener noreferrer" 
        className=" bg-white rounded-full p-2 transition-all duration-300 group-hover:bg-[#e91e63] no-underline"
    >
        <FiMapPin className="w-5 h-5 text-black transition-all duration-300 group-hover:text-white" />
    </a>
    <span className="text-sm text-gray-100 whitespace-normal">
        <a 
            href="https://goo.gl/maps/QthnDs5x7BjAof6u7" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-gray-200 no-underline hover:text-gray-400"
        >
            Protokol Yolu No:45, 10. Yıl Cd. 34010 Biruni Teknopark Kat:8 Zeytinburnu/İstanbul
        </a>
    </span>
</div>


    {/* Telefon ve E-posta Alanı */}
    <div className="flex flex-col space-y-3 h-[fit-content] md:h-auto">

{/* Telefon */}
<div className="group flex flex-row items-center space-x-2">
    <a 
        href="tel:+90 555 096 00 00" 
        className="bg-white rounded-full p-2 transition-all duration-300 group-hover:bg-[#e91e63] no-underline"
    >
        <FiPhone className="w-5 h-5 text-black transition-all duration-300 group-hover:text-white" />
    </a>
    <span className="text-sm text-gray-200">
        <a href="tel:+90 555 096 00 00" className="no-underline text-gray-200 hover:text-gray-400">
            +90 555 096 00 00
        </a>
    </span>
</div>

{/* E-posta */}
<div className="group flex flex-row items-center space-x-2">
    <a 
        href="mailto:info@cskyazilim.com" 
        className="bg-white rounded-full p-2 transition-all duration-300 group-hover:bg-[#e91e63] no-underline"
    >
        <FiMail className="w-5 h-5 text-black transition-all duration-300 group-hover:text-white" />
    </a>
    <span className="text-sm text-gray-200">
        <a href="mailto:info@cskyazilim.com" className="no-underline text-gray-200 hover:text-gray-400">
            info@cskyazilim.com
        </a>
    </span>
</div>

</div>


</div>

      </div>
        </div>
    
    </footer>

  );
}

export default Footer;
