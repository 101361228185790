import React from 'react';
import { Link } from 'react-router-dom';

function CardItem(props) {
  return (
    <>
      <li className='card__item flex flex-1 w-17/26 sm:w-full md:w-1/2 lg:w-1/3 md:mx-3 md:my-0 rounded-full'>
        <Link className='cards__item__link flex flex-col w-full shadow-lg rounded-lg overflow-hidden no-underline ' to={props.path}>
          <figure className='cards__item__pic-wrap relative w-full overflow-hidden pt-[67%]' data-category={props.label}>
            <img
              className='cards__item__img'
              alt={props.alt}
              title={props.title}
              src={props.src}
            />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItem;