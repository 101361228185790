import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import {motion} from "framer-motion"
import { useTranslation } from 'react-i18next';


function Cards() {
 const {t} = useTranslation(["cards"])
  return (
<div className='p-16 bg-white'>
    <h1 className='text-center  px-3 py-3 text-white bg-[#f9004d] rounded-full  md:text-2xl  w-full lg:w-1/4 m-auto'>
        {t("service")}
    </h1>
    <div className='flex flex-col items-center max-w-screen-lg  w-full md:w-9/10 m-auto'>
        <div className='relative flex flex-col my-24 min-w-full w-64 tiny:w-80 '>
            <motion.div
                initial={{ opacity: 0.5 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{duration: 0.7}}
            >
                <ul className=' w-full  mb-6 space-y-6 md:space-y-0 md:flex'>
                    <CardItem
                        src='images/fintech.jpg'
                        alt="finans"
                        title="Finansal Teknoloji"
                        text={t("fintechText")}
                        label={t('fintechLabel')}
                        path='/fintech'
                        className='flex flex-1 mx-4 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-200'
                    />
                    <CardItem
                        src='images/crm.jpg'
                        alt="crm"
                        title="CRM"
                        text={t('crmText')}
                        label='CRM'
                        path='/crm'
                        className='flex flex-1 mx-4 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-200'
                    />
                </ul>
                <ul className='space-y-6 md:space-y-0 md:flex'>
                    <CardItem
                        src='images/website.jpg'
                        alt="wep tasarım"
                        title="Wep Tasarım"
                        text={t('webText')}
                        label={t('webLabel')}
                        path='/webdesign'
                        className='flex flex-1 mx-4 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-200'
                    />
                    <CardItem
                        src='images/etrade.jpg'
                        alt="ticaret"
                        title="E-Ticaret"
                        text={t("ecoText")}
                        label={t("ecoLabel")}
                        path='/ecommerce'
                        className='flex flex-1 mx-4 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-200'
                    />
                    <CardItem
                        src='images/accounting.jpg'
                        alt="muhasebe"
                        title="Muhasebe Programları"
                        text={t('accoText')}
                        label={t('accoLabel')}
                        path='/accounting'
                        className='flex flex-1 mx-4 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-200'
                    />
                </ul>
            </motion.div>
        </div>
    </div>
</div>

  );
}

export default Cards;