import React from "react";
import "../../App.css";
import "../pagescss/About.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function Support() {
  const {t}=useTranslation(['support'])
  return (
    <div className="about">
      <div className="main">
  
        <motion.div
 initial={{ opacity: 0 }}
 animate={{ opacity: 1 }}
 exit={{ opacity: 0 }}
 transition={{duration: 0.7}}
    >
        <div className="about-text">
        <img src="images/support.jpg" alt="" />
          <h1>{t('supportTitle')}</h1>

          <h3>{t('supportHeadOne')}</h3>
          <p>
            {t('supportTextOne')}
          </p>
          <Link to="/contact">
            <button  className="aboutBtn"  type=""> {t('supportBtn')}</button>
          </Link>
        </div>
        </motion.div>
      </div>
    </div>
  );
}
