import React from "react";
import "../../App.css";
import "../pagescss/About.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function Crm() {
const {t}=useTranslation(['crm'])
  return (
    <div className="about">
      <div className="main">
  
        <motion.div
 initial={{ opacity: 0 }}
 animate={{ opacity: 1 }}
 exit={{ opacity: 0 }}
 transition={{duration: 0.7}}
    >
        <div className="about-text">
        <img src="images/crm.jpg" alt="" />
          <h1>{t('crmTitle')}</h1>

          <h3>{t("crmHeadOne")}</h3>
          <p>
          {t('crmTextOne')}
          </p>
          <h3>{t('crmHeadTwo')}</h3>

          <ul>
            <li>
              <h4>{t('crmH1')}</h4>
              {t('crmText1')}
            </li>
            <li>
              <h4>{t('crmH2')}</h4>
              {t('crmText2')}
              
            </li>
            <li>
              <h4> {t('crmH3')}</h4>
              {t('crmText3')}
              
            </li>
            <li>
              <h4>{t('crmH4')}</h4>
              {t('crmText4')}
            </li>
            <li>
              <h4>{t('crmH5')}</h4>{t('crmText5')}{" "}
            </li>
          </ul>

          <Link to="/contact">
            <button className="aboutBtn" type="">{t('crmBtn')}</button>
          </Link>
        </div>
        </motion.div>
      </div>
    </div>
  );
}
