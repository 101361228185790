import React, { useState } from "react";

import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";
import logo1 from "../logo1.png";
import logo3 from "../logo4.png";
import { motion } from "framer-motion";
import Language from "./language/Language";
import { useTranslation } from "react-i18next";

function Navbar() {
  const { t } = useTranslation(["common"]);

  const [click, setClick] = useState(false);
  const [navbar, setNavbar] = useState(false);
  //Birden fazlaelement navbarla set edildi

  const { pathname } = useLocation();

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <nav className={navbar ? "navbar active" : "navbar"}>
        <div className="navbarr-containerr">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <div className="logos">
              <img src={navbar ? logo3 : logo1} alt="CSK" />
            </div>
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i
              className={
                click
                  ? navbar
                    ? "fas fa-times active"
                    : "fas fa-times"
                  : navbar
                  ? "fas fa-bars active"
                  : "fas fa-bars"
              }
            />
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <ul
              className={
                click
                  ? navbar
                    ? "nav-menu gr active"
                    : "nav-menu  active"
                  : navbar
                  ? "nav-menu"
                  : "nav-menu"
              }
            >
              <li className="nav-item">
                <Link
                  to="/"
                  className={
                    pathname === "/"
                      ? navbar
                        ? "nav-links bg active "
                        : "nav-links bg "
                      : navbar
                      ? "nav-links active"
                      : "nav-links"
                  }
                  onClick={closeMobileMenu}
                >
                  {/* Ana Sayfa */}
                  {t("homePage")}
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/about"
                  className={
                    pathname === "/about"
                      ? navbar
                        ? "nav-links bg active "
                        : "nav-links bg "
                      : navbar
                      ? "nav-links active"
                      : "nav-links"
                  }
                  onClick={closeMobileMenu}
                >
                 {t("aboutUs")}
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/services"
                  className={
                    pathname === "/services"
                      ? navbar
                        ? "nav-links bg active "
                        : "nav-links bg "
                      : navbar
                      ? "nav-links active"
                      : "nav-links"
                  }
                  onClick={closeMobileMenu}
                >
                  {t("services")}
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/references"
                  className={
                    pathname === "/references"
                      ? navbar
                        ? "nav-links bg active "
                        : "nav-links bg "
                      : navbar
                      ? "nav-links active"
                      : "nav-links"
                  }
                  onClick={closeMobileMenu}
                >
                  {t("references")}
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/contact"
                  className={
                    pathname === "/contact"
                      ? navbar
                        ? "nav-links bg active "
                        : "nav-links bg "
                      : navbar
                      ? "nav-links active"
                      : "nav-links"
                  }
                  onClick={closeMobileMenu}
                >
                  {t("contact")}
                </Link >
                
              </li>
              <li className="nav-item">
              <Language/>
              </li>
              
            </ul>
          </motion.div>
        </div>
        
      
        
        
      </nav>
    </>
  );
}
export default Navbar;
