import React from "react";

import Navbar from "./components/Navbar";

import Home from "./components/pages/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Services from "./components/pages/Services";
import References from "./components/pages/References"
import Fintech from "./components/servicespages/Fintech";
import Crm from "./components/servicespages/Crm";
import Accounting from "./components/servicespages/Accounting";
import Ecommerce from "./components/servicespages/Ecommerce.js";
import Support from "./components/servicespages/Support";
import Webdesign from "./components/servicespages/Webdesign";
import About from "./components/pages/About";
import Footer from "./components/Footer";
import Contact from "./components/pages/Contact";
import ScrollToTop from "./components/ScrollToTop";
import {AnimatePresence} from "framer-motion"

function Routers() {
  return (
    <div>
      <Router>
        <ScrollToTop />
        <Navbar />
        <AnimatePresence>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/about" component={About} />
          <Route path="/services" component={Services} />
          <Route path="/references" component={References}/>
          <Route path="/contact" component={Contact} />
          <Route path="/fintech" component={Fintech} />
          <Route path="/crm" component={Crm} />
          <Route path="/accounting" component={Accounting} />
          <Route path="/webdesign" component={Webdesign} />
          <Route path="/ecommerce" component={Ecommerce} />
          <Route path="/support" component={Support} />
        </Switch>
        </AnimatePresence>
        <Footer />
      </Router>
    </div>
  );
}

export default Routers;
