import React from "react";
import "../../App.css";
import "../pagescss/About.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function Accounting() {
  const {t}=useTranslation(['accounting'])
  return (
    <div className="about">
      <div className="main">

        <motion.div
 initial={{ opacity: 0 }}
 animate={{ opacity: 1 }}
 exit={{ opacity: 0 }}
 transition={{duration: 0.7}}
    >
        <div className="about-text">
        <img src="images/accounting.jpg" alt="accounting" />
          <h1>{t('accountingTitle')}</h1>
          {/* <h5>Developer <span> & Designer </span></h5> */}
          <h3>{t('accountingHeadOne')}</h3>
          <p>
      {t('accountingTextOne')}
          </p>
          <h3>{t('accountingHeadTwo')}</h3>
          <p>
          {t('accountingTextTwo')}
          </p>
          <h3>{t('accountingHeadTree')}</h3>
          <ul>
            <li>{t('accountingLiText1')}</li>
            <li>{t('accountingLiText2')}</li>
            <li>{t('accountingLiText3')}</li>
            <li>{t('accountingLiText4')}</li>
          </ul>

          <Link to="/contact">
            <button  className="aboutBtn" type="">{t('accountingBtn')}</button>
          </Link>
        </div>
        </motion.div>
      </div>
    </div>
  );
}
