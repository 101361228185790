import React from "react";
import "../../App.css";
import "../pagescss/About.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function Ecommerce() {
  const {t}=useTranslation(['ecommerce'])
  return (
    <div className="about">
      <div className="main">

        <motion.div
 initial={{ opacity: 0 }}
 animate={{ opacity: 1 }}
 exit={{ opacity: 0 }}
 transition={{duration: 0.7}}
    >
        <div className="about-text">
        <img src="images/etrade2.jpg" alt="" />
          <h1>{t('ecommerceTitle')}</h1>

          <h3>{t('ecommerceHeadOne')}</h3>
          <p>
          {t('ecommerceTextOne')}
          </p>
          <h3>{t('ecommerceHeadTwo')}</h3>
          <p>
            {" "}
            {t('ecommerceTextTwo')}
          </p>
          <ul>
            <li> {t('ecommerceTextLi1')}</li>
            <li> {t('ecommerceTextLi2')}</li>
            <li>
            {t('ecommerceTextLi3')}
            </li>
            <li>
            {t('ecommerceTextLi4')}
            </li>
            <li> {t('ecommerceTextLi5')}</li>
            <li>
            {t('ecommerceTextLi6')}
            </li>
            <li>
            {t('ecommerceTextLi7')}
            </li>
            <li> {t('ecommerceTextLi8')}</li>
          </ul>

          <Link to="/contact">
            <button className="aboutBtn"  type="">{t('ecommerceBtn')}</button>
          </Link>
        </div>
        </motion.div>
      </div>
    </div>
  );
}
