import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import './/Language.css'


function Language() {
  const [navbar, setNavbar] = useState(false);
 
 
 const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);





  const { i18n } = useTranslation();

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      localStorage.getItem("i18nextLng")?.length > 2
    ) {
      i18next.changeLanguage("tr");
    }
  }, []);
  


 
  
  return (
 
      <div  className={navbar?"lang-buttons active":"lang-buttons "}
     >
     
        <button className={(i18n.language === "en") && (navbar?"langBtn active":"langBtn ")} onClick={handleLanguageChange} value="en" >EN</button>
        <button className={(i18n.language === "tr") && (navbar?"langBtntr active":"langBtntr ")} onClick={handleLanguageChange} value="tr" >TR</button>
        
      </div>
      
  );
}

export default Language;
